<template>
  <th-page-wrapper>
    <th-datatable
      :headers="headers"
      :show-operations="false"
      :resource-limit="1000"
      :show-tool-header="false"
      resource="voucherLogs"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  name: 'VoucherLogsAll',
  metaInfo() {
    return {
      title: this.$t('pages.voucher-logs.title')
    }
  },
  data() {
    return {
      headers: [
        {
          field: 'code',
          label: this.$t('pages.voucher_logs.all.headers.code'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: function (row) {
            const newCode = safeGet(row, 'new.code')
            const oldCode = safeGet(row, 'old.code')
            if (!newCode && !oldCode) return '-'
            return newCode || oldCode
          }
        },
        {
          field: 'type',
          label: this.$t('pages.voucher_logs.all.headers.type'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'date',
          label: this.$t('pages.voucher_logs.all.headers.date'),
          minWidth: 140,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            const date = safeGet(row, 'created_at.iso')
            if (!date) return '-'
            return this.$date.formatDateWithTimezone(date, row.timezone)
          }
        },
        {
          field: 'change_financial',
          label: this.$t('pages.voucher_logs.all.headers.change_financial'),
          minWidth: 140,
          truncate: true,
          fallback: '-',
          align: 'right',
          formatter: (row) => {
            const change = safeGet(row, 'change_financial')
            const currency =
              safeGet(row, 'new.currency') ||
              safeGet(row, 'old.currency') ||
              'EUR'
            const changeFinancial = Number(change)

            if (!change || !Number.isFinite(changeFinancial)) return '-'
            return this.$formatCurrency(changeFinancial, currency)
          }
        },
        {
          field: 'currency',
          label: this.$t('pages.voucher_logs.all.headers.currency'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            const currency =
              safeGet(row, 'new.currency') ||
              safeGet(row, 'old.currency') ||
              'EUR'

            return currency
          }
        },
        {
          field: 'issuer',
          label: this.$t('pages.voucher_logs.all.headers.issuer'),
          fallback: '-',
          minWidth: 140,
          truncate: true,
          formatter: function (row) {
            const issuer = safeGet(row, 'issuer')
            if (!issuer) return '-'

            const entries = Object.entries(issuer).filter(
              (item) =>
                item &&
                item[0] &&
                [
                  'email',
                  'branch_external_custom_id',
                  'branch',
                  'register'
                ].includes(item[0])
            )

            return (
              entries
                .map((item) => {
                  return `${item[0]}: ${item[1]}`
                })
                .join(',')
                .trim() || '-'
            )
          }
        }
      ]
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.voucher_logs.title')
        })
      })
    }
  }
}
</script>

<style scoped></style>
